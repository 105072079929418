import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllDiagnostic } from 'services'

const initialState = {
  list: [],
  selected: {},
  loading: false,
}

export const fetchDiagnosticList = createAsyncThunk('diagnostic/get', async (thunkAPI) => {
  try {
    const response = await getAllDiagnostic()

    if (response.status === 200) {
      return response
    }

    return thunkAPI.rejectWithValue(response)
  } catch (error) {
    console.log('Error', error.response.data)
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

const diagnosticSlice = createSlice({
  name: 'diagnostic',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDiagnosticList.pending]: (state) => {},
    [fetchDiagnosticList.rejected]: (state) => {},
    [fetchDiagnosticList.fulfilled]: (state, { payload }) => {
      state.list = payload.data

      return state
    },
  },
})

const { reducer } = diagnosticSlice
export const selectDiagnosticList = (state) => state.diagnostic.list
export default reducer
