import AxiosClient from 'utils/axiosClient'

export const getAllDiagnostic = (params) => {
  const url = `api/diagnostic`
  return AxiosClient.get(url, { params })
}

export const getDiagnosticById = (id) => {
  const url = `api/diagnostic/${id}`
  return AxiosClient.get(url)
}
