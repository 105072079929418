import React from 'react'
import { Navigate } from 'react-router-dom'
import { getToken } from 'services'

const PrivateRoute = ({ children }) => {
  const isLoggedIn = getToken()

  return isLoggedIn ? children : <Navigate to='/auth/login' />
}

export default PrivateRoute
