import { Loading } from 'components/common'
import React, { Suspense } from 'react'
import AppRoutes from 'routes'

const App = () => {
  if (process.env.NODE_ENV === 'production') {
    window.console = window.console || {}
    window.console.log = () => {}
    window.console.debug = () => {}
    window.console.error = () => {}
  }

  return (
    <Suspense fallback={<Loading />}>
      <AppRoutes />
    </Suspense>
  )
}

export default App
