import React from 'react'
import { Navigate } from 'react-router-dom'
import { getToken } from 'services'

const PublicRoute = ({ children }) => {
  const isLoggedIn = getToken()

  return !isLoggedIn ? children : <Navigate to='/diagnostic' />
}

export default PublicRoute
