import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { en, vi } from 'config/images'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLng, setLng } from 'services'

const listLanguage = [
  {
    icon: vi,
    label: 'language.vietnamese',
    language: 'vi',
  },
  {
    icon: en,
    label: 'language.english',
    language: 'en',
  },
]

const LanguagePopover = () => {
  const { t, i18n } = useTranslation()

  const defaultLanguage = listLanguage.find((item) => item.language === getLng()) || listLanguage[0]

  const [language, setLanguage] = useState(defaultLanguage)

  const changeLanguage = (value) => {
    i18n.changeLanguage(value.language)
    setLanguage(value)
    setLng(value.language)
  }

  return (
    <div>
      <Listbox value={language} onChange={changeLanguage}>
        {({ open }) => (
          <div className='relative'>
            <Listbox.Button className='relative w-full py-2 pl-3 pr-10 text-left rounded-md cursor-default sm:text-sm"'>
              <span className='flex items-center'>
                <img src={language.icon} alt='' className='flex-shrink-0 w-6 h-6 rounded-full' />
                <span className='hidden ml-3 text-sm font-medium text-gray-700 truncate md:block'>
                  {t(language.label)}
                </span>
              </span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none'>
                <ChevronUpIcon
                  className={`transform duration-300 ${open ? 'rotate-180' : 'rotate-360'} w-5 h-5 text-gray-500`}
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options className='absolute right-0 z-10 py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg w-44 max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                {listLanguage.map((lng, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `${active ? 'bg-gray-100' : 'text-gray-900'}
                        cursor-pointer select-none relative py-2 pl-3 pr-9 w-full`
                    }
                    value={lng}
                  >
                    {({ selected }) => (
                      <>
                        <div className='flex items-center'>
                          <img src={lng.icon} alt='' className='flex-shrink-0 w-6 h-6 rounded-full' />
                          <span className={`${selected ? 'font-medium' : 'font-normal'} ml-3 block truncate text-sm`}>
                            {t(lng.label)}
                          </span>
                        </div>

                        {selected && (
                          <span className='absolute inset-y-0 right-0 flex items-center pl-3 pr-4 text-primary-500'>
                            <CheckIcon className='w-5 h-5' aria-hidden='true' />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export default LanguagePopover
