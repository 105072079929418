export const getAccessToken = () => localStorage.getItem('accessToken')

export const setAccessToken = (value) => localStorage.setItem('accessToken', value)

export const getRefreshToken = () => localStorage.getItem('refreshToken')

export const setRefreshToken = (value) => localStorage.setItem('refreshToken', value)

export const getUser = () => JSON.parse(localStorage.getItem('user'))

export const setUser = (value) => localStorage.setItem('user', JSON.stringify(value))

export const getUserStatus = () => JSON.parse(localStorage.getItem('user'))?.status

export const setAuth = ({ data }) => {
  setAccessToken(data.tokens.access.token)
  setRefreshToken(data.tokens.refresh.token)
  setUser(data.user)
}

export const getLng = () => localStorage.getItem('lng')

export const setLng = (value) => localStorage.setItem('lng', value)

export const clearLocalStorage = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
  localStorage.removeItem('user')
}
