import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { changePassword, clearLocalStorage, login, logout, setUser } from 'services'

export const handleLogin = createAsyncThunk('auth/login', async ({ username, password }, thunkAPI) => {
  try {
    const body = {
      username,
      password,
    }

    const response = await login(body)

    if (response.status === 200) {
      setUser(response.data.user)

      return response
    }

    return thunkAPI.rejectWithValue(response)
  } catch (error) {
    console.log('Error', error.response.data)
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const handleLogout = createAsyncThunk('auth/logout', async (thunkAPI) => {
  try {
    const response = await logout()

    if (response.status === 200) {
      clearLocalStorage()

      return response
    }

    return thunkAPI.rejectWithValue(response)
  } catch (error) {
    console.log('Error', error.response.data)
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const handleChangePassword = createAsyncThunk(
  'auth/change-password',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    try {
      const body = {
        currentPassword,
        newPassword,
      }

      const response = await changePassword(body)

      if (response.status === 200) {
        clearLocalStorage()

        return response
      }

      return thunkAPI.rejectWithValue(response)
    } catch (error) {
      console.log('Error', error.response.data)
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoggedIn: false,
    logging: false,
    currentUser: undefined,
  },
  reducers: {},
  extraReducers: {
    [handleLogin.pending]: (state) => {
      state.logging = true
    },
    [handleLogin.rejected]: (state) => {
      state.logging = false
    },
    [handleLogin.fulfilled]: (state, { payload }) => {
      state.isLoggedIn = true
      state.logging = false
      state.currentUser = payload.data.user
      return state
    },
    [handleLogout.fulfilled]: (state) => {
      state.isLoggedIn = false
      state.currentUser = undefined
      return state
    },
    [handleChangePassword.fulfilled]: (state) => {
      state.isLoggedIn = false
      state.currentUser = undefined
      return state
    },
  },
})

const { reducer } = authSlice
export const authSelector = (state) => state.auth
export default reducer
