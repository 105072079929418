import { NotFound } from 'components/common'
import { PrivateRoute, PublicRoute } from 'components/guards'
import { AuthLayout, MainLayout } from 'components/layouts'
import { ForgotPasswordPage, LoginOtpPage, LoginPage } from 'pages/auth'
import { DiagnosticList } from 'pages/diagnostic'
import { ChangePasswordPage, ProfilePage } from 'pages/patient'
import React from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import { getUserStatus } from 'services'

const AppRoutes = () => {
  const userStatus = getUserStatus()

  return useRoutes([
    {
      path: '/auth',
      element: (
        <PublicRoute>
          <AuthLayout />
        </PublicRoute>
      ),
      children: [
        { index: true, element: <Navigate to='login' replace /> },
        { path: 'login', element: <LoginPage /> },
        { path: 'login-otp', element: <LoginOtpPage /> },
        { path: 'forgot-password', element: <ForgotPasswordPage /> },
      ],
    },
    {
      path: '/',
      element: (
        <PrivateRoute>
          <MainLayout />
        </PrivateRoute>
      ),
      children: [
        { index: true, element: <Navigate to='diagnostic' replace /> },
        {
          path: 'patient',
          children: [
            { index: true, element: <Navigate to='profile' replace /> },
            { path: 'profile', element: <ProfilePage /> },
            { path: 'change-password', element: <ChangePasswordPage /> },
          ],
        },
        { path: 'diagnostic', element: <DiagnosticList /> },
      ],
    },
    { path: '/404', element: <NotFound /> },
    { path: '*', element: <Navigate to='404' replace /> },
  ])
}

export default AppRoutes
