import AxiosClient from 'utils/axiosClient'

export const login = (body) => {
  const url = `api/auth/login`
  return AxiosClient.post(url, body)
}

export const logout = () => {
  const url = `api/auth/logout`
  return AxiosClient.post(url)
}

export const refreshTokens = () => {
  const url = `api/auth/refresh-token`
  return AxiosClient.post(url)
}

export const changePassword = (body) => {
  const url = `api/auth/change-password`
  return AxiosClient.put(url, body)
}
