import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { REACT_APP_TECHNICAL_SUPPORT_NUMBER } = process.env

  const goBack = () => navigate('/')

  return (
    <section className='px-4 py-24 mx-auto max-w-7xl'>
      <div className='w-full mx-auto text-center lg:w-2/3'>
        <h1 className='mb-4 text-6xl font-thin text-gray-900'>404</h1>
        <p className='mb-3 text-xl font-bold text-gray-900 md:text-2xl'>{t('text.404-p1')}</p>
        <p className='mb-3 text-lg font-medium text-gray-700'>
          {t('text.404-p2')}{' '}
          <span className='underline cursor-pointer' onClick={goBack}>
            {t('text.404-p3')}
          </span>{' '}
          {t('text.404-p4')}{' '}
          {REACT_APP_TECHNICAL_SUPPORT_NUMBER === '' ? t('text.technicians') : REACT_APP_TECHNICAL_SUPPORT_NUMBER}{' '}
          {t('text.404-p5')}
        </p>
      </div>
    </section>
  )
}

export default NotFound
